import {ApiError} from '@rambler-id/errors'
import {ApiCallback} from '../types'

export const get = (
  endpoint: string,
  callback: ApiCallback,
  options: {
    withCredentials?: boolean
    headers?: Record<string, string>
  } = {}
): void => {
  const {withCredentials = false, headers} = options

  const request = new XMLHttpRequest()

  request.withCredentials = withCredentials
  request.open('get', endpoint, true)

  for (const key in headers) {
    if (headers.hasOwnProperty(key)) {
      request.setRequestHeader(key, headers[key])
    }
  }

  request.onreadystatechange = () => {
    if (request.readyState !== 4) return
    if (request.status === 0) return
    callback(null, request.responseText)
  }

  request.onerror = () => {
    callback(
      new ApiError({
        message: 'location does not respond',
        code: 0,
        details: {
          method: endpoint
        }
      })
    )
  }

  request.send(null)
}
