import {ApiError} from '@rambler-id/errors'
import {ApiCallback} from '../types'

export const loadScript = (endpoint: string, callback: ApiCallback): void => {
  const script = document.createElement('script')

  script.src = endpoint

  script.onload = () => {
    callback(null)

    if (document.body) {
      document.body.removeChild(script)
    }
  }

  script.onerror = () => {
    callback(new ApiError({message: 'script does not loaded'}))
  }

  if (document.body) {
    document.body.appendChild(script)
  }
}
