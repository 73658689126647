import {HelperCallback} from '../types'
import {createDebug} from '@rambler-id/debug'

const debug = createDebug('id:helper:emitter')

export class EventEmitter {
  protected listeners: Record<string, HelperCallback[]> = {}

  protected existsListener(
    eventType: string,
    callback: HelperCallback
  ): boolean {
    return (
      this.listeners[eventType] &&
      this.listeners[eventType].indexOf(callback) !== -1
    )
  }

  /**
   * Слушать событие бесконечно
   */
  public addListener(eventType: string, callback: HelperCallback): void {
    debug(`addListener ${eventType}`)

    this.listeners[eventType] = this.listeners[eventType] || []

    if (
      typeof callback === 'function' &&
      !this.existsListener(eventType, callback)
    ) {
      this.listeners[eventType].push(callback)
    }
  }

  /**
   * Прекращать прослушивание события
   */
  public removeListener(eventType: string, callback: HelperCallback): void {
    debug(`removeListener ${eventType}`)

    if (this.existsListener(eventType, callback)) {
      this.listeners[eventType].splice(
        this.listeners[eventType].indexOf(callback),
        1
      )
    }
  }

  /**
   * Запуск события
   */
  public emit(eventType: string, ...args: unknown[]): void {
    debug(`emit ${eventType}`)

    if (this.listeners[eventType]) {
      this.listeners[eventType].forEach((callback: HelperCallback) => {
        callback(...args)
      })
    }
  }
}
