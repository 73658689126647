import * as cookies from '@rambler-id/cookies'
import {v4 as uuid} from '@lukeed/uuid'
import {mobile} from '@rambler-id/browser'
import {ApiCallback, SberIdNotificationBannerOptions} from '../types'
import {BaseWidget} from './base-widget'
import {EventEmitter} from '../utils/event-emitter'
import {get} from '../api/get'

const CLIENT_ID = 'fbdea60d-1f60-425f-9917-d2fe53b2ac3f'

export interface UserInfo {
  firstname?: string
  partname?: string
  surname?: string
}

export const getUserInfo = (callback: ApiCallback): void => {
  get(
    `https://id.sber.ru/CSAFont/api/userdata?client_id=${CLIENT_ID}`,
    (error, response) => {
      callback(error, response && JSON.parse(response))
    },
    {
      withCredentials: true,
      headers: {'Process-ID': uuid().replace(/-/g, '')}
    }
  )
}

const BANNER_CLOSED = 'sberIdNotificationBannerClosed'
const BANNER_CLOSE_COUNT = 'sberIdNotificationBannerCloseCount'

const BANNER_CLOSED_OPTIONS = {
  path: '/',
  domain: window.location.hostname.split('.').slice(-2).join('.')
}

const BANNER_CLOSED_EXPIRES = [1 / 12, 1, 7, 28]
const BANNER_CLOSE_COUNT_EXPIRES = 365

export const isBannerClosed = (): boolean =>
  cookies.getItem<boolean>(BANNER_CLOSED) ?? false

export interface PersonalizedBannerOptions
  extends SberIdNotificationBannerOptions {
  displayName?: string
}

export const enum Events {
  CLICK = 'click',
  DESTROY = 'destroy'
}

export class NotificationBanner extends EventEmitter implements BaseWidget {
  private options: PersonalizedBannerOptions
  private container: HTMLDivElement
  private label: HTMLDivElement
  private button: HTMLAnchorElement
  private buttonText: HTMLSpanElement
  private closeButton: HTMLAnchorElement
  private autoCloseTimeout?: number

  public constructor(options: Partial<PersonalizedBannerOptions>) {
    super()
    this.options = {
      ...options,
      fontFamily: options.fontFamily ?? 'Roboto, sans-serif',
      position: options.position ?? 'bottom-right',
      autoCloseDuration: options.autoCloseDuration ?? 0,
      offset: {
        top: 30,
        left: 30,
        right: 30,
        bottom: 30,
        ...options.offset
      }
    }

    // NOTE: fallback y-position for prev position values (left | right)
    const [positionX, positionY = 'bottom'] = this.options.position
      .split('-')
      .reverse() as (keyof SberIdNotificationBannerOptions['offset'])[]

    this.container = document.createElement('div')
    this.container.style.position = 'fixed'
    this.container.style.opacity = '0'
    this.container.style.background = '#fff'
    this.container.style.border = '1px solid #E3E3E3'
    this.container.style.boxShadow = '0px 10px 24px rgba(45, 73, 88, 0.15)'
    this.container.style.borderRadius = mobile ? '0' : '5px'
    this.container.style.padding = '20px'
    this.container.style.display = 'flex'
    this.container.style.width = mobile ? '100%' : 'auto'
    this.container.style.boxSizing = 'border-box'
    this.container.style.flexDirection = 'column'
    this.container.style.fontFamily = this.options.fontFamily
    this.container.style.minWidth = '320px'
    this.container.style.transition = 'opacity 200ms'
    this.container.style[positionX] = mobile
      ? '0'
      : this.options.offset[positionX] + 'px'
    this.container.style[positionY] = mobile
      ? '0'
      : this.options.offset[positionX] + 'px'

    this.label = document.createElement('div')
    this.label.style.fontSize = '15px'
    this.label.style.lineHeight = '18px'
    this.label.style.color = '#767676'
    this.label.style.marginBottom = '16px'
    this.label.style.fontWeight = '600'
    this.label.textContent = 'Вход по Сбер ID'
    this.container.appendChild(this.label)

    this.closeButton = document.createElement('a')
    this.closeButton.setAttribute('role', 'button')
    this.closeButton.style.fontSize = '0'
    this.closeButton.style.lineHeight = '12px'
    this.closeButton.style.color = '#767676'
    this.closeButton.style.position = 'absolute'
    this.closeButton.style.top = '10px'
    this.closeButton.style.right = '10px'
    this.closeButton.style.cursor = 'pointer'
    this.closeButton.style.display = 'flex'
    this.closeButton.style.padding = '10px'
    this.closeButton.innerHTML =
      '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.65094L10.3675 0.282877C10.7431 -0.0927631 11.3485 -0.0942703 11.721 0.27826C12.0961 0.653395 12.0914 1.25686 11.7164 1.63195L7.34886 6L11.7164 10.368C12.0914 10.7431 12.0961 11.3466 11.721 11.7217C11.3485 12.0943 10.7431 12.0928 10.3675 11.7171L6 7.34906L1.63247 11.7171C1.25688 12.0928 0.651469 12.0943 0.278986 11.7217C-0.0960822 11.3466 -0.0914182 10.7431 0.283602 10.368L4.65114 6L0.283602 1.63195C-0.0914182 1.25686 -0.0960822 0.653395 0.278986 0.27826C0.651469 -0.0942703 1.25688 -0.0927631 1.63247 0.282877L6 4.65094Z" fill="currentColor"/></svg>'
    this.container.appendChild(this.closeButton)

    this.button = document.createElement('a')
    this.button.setAttribute('role', 'button')
    this.button.style.display = 'flex'
    this.button.style.alignItems = 'center'
    this.button.style.justifyContent = 'center'
    this.button.style.textDecoration = 'none'
    this.button.style.height = '48px'
    this.button.style.fontSize = '16px'
    this.button.style.fontWeight = '400'
    this.button.style.lineHeight = '19px'
    this.button.style.color = '#fff'
    this.button.style.backgroundColor = '#21A038'
    this.button.style.padding = '0 23px'
    this.button.style.borderRadius = '4px'
    this.button.innerHTML =
      '<svg width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 0C16.0927 0 18.9337 1.08103 21.1657 2.88421L18.6371 4.74793C17.0315 3.64848 15.0899 3.00354 13 3.00354C7.48924 3.00354 3.00587 7.48999 3.00587 13.0013C3.00587 18.5126 7.48924 22.9965 13 22.9965C18.5134 22.9965 22.9968 18.5126 22.9968 13.0013C22.9968 12.9118 22.9941 12.8223 22.9924 12.7328L25.7912 10.6699C25.9289 11.4245 26 12.2055 26 13.0013C26 20.1807 20.1795 26 13 26C5.82135 26 0 20.1807 0 13.0013C0 5.81931 5.82135 0 13 0ZM23.2856 5.05241C23.9006 5.84651 24.4262 6.71169 24.8456 7.63565L13.0002 16.3673L8.05093 13.2628V9.52921L13.0002 12.6337L23.2856 5.05241Z" fill="currentColor"/></svg>'
    this.container.appendChild(this.button)

    this.buttonText = document.createElement('span')
    this.buttonText.style.marginLeft = '8px'
    this.buttonText.textContent = options.displayName
      ? `Войти как ${options.displayName}`
      : 'Войти по Сбер ID'
    this.button.appendChild(this.buttonText)
  }

  private onLoginClick = (): void => {
    this.emit(Events.CLICK)
  }

  private onCloseClick = (): void => {
    const closeCount = cookies.getItem<number>(BANNER_CLOSE_COUNT) ?? 0

    cookies.setItem(BANNER_CLOSED, true, {
      ...BANNER_CLOSED_OPTIONS,
      expires: BANNER_CLOSED_EXPIRES[Math.min(closeCount, 3)]
    })
    cookies.setItem(BANNER_CLOSE_COUNT, closeCount + 1, {
      ...BANNER_CLOSED_OPTIONS,
      expires: BANNER_CLOSE_COUNT_EXPIRES
    })
    this.destroy()
  }

  public attach(): void {
    this.button.addEventListener('click', this.onLoginClick)
    this.closeButton.addEventListener('click', this.onCloseClick)
    document.body.appendChild(this.container)
    window.setTimeout(() => {
      this.container.style.opacity = '1'
    }, 0)

    if (this.options.autoCloseDuration) {
      this.autoCloseTimeout = window.setTimeout(
        this.onCloseClick,
        this.options.autoCloseDuration
      )
    }
  }

  public destroy(): void {
    clearTimeout(this.autoCloseTimeout)
    this.container.style.opacity = '0'
    this.button.removeEventListener('click', this.onLoginClick)
    this.closeButton.removeEventListener('click', this.onCloseClick)
    this.emit(Events.DESTROY)
    window.setTimeout(() => {
      document.body.removeChild(this.container)
    }, 200)
  }
}
