import {Hub} from '@sentry/browser'
import {HelperCallback} from '../types'
import {loadScript} from './dom'
import {SENTRY_NAMESPACE, SENTRY_THRESHOLD} from './constants'

let Sentry: Hub
let sentryQueue: HelperCallback[]

const NODE_ENV = process.env.NODE_ENV
const VERSION = process.env.VERSION
const CDN_ORIGIN = process.env.CDN_ORIGIN
const CDN_PREFIX = process.env.CDN_PREFIX

export const loadSentry = async (callback: HelperCallback): Promise<void> => {
  if (NODE_ENV === 'development' || Math.random() > SENTRY_THRESHOLD) {
    return
  }

  if (Sentry) {
    callback(Sentry)

    return
  }

  if (sentryQueue) {
    sentryQueue.push(callback)

    return
  }

  sentryQueue = [callback]
  loadScript(`${CDN_ORIGIN}${CDN_PREFIX}/${VERSION}/sentry.js`, (error) => {
    if (Sentry) {
      sentryQueue?.forEach((callback) => callback(Sentry))

      return
    }

    if (error) {
      return
    }

    Sentry = window[SENTRY_NAMESPACE]
    delete window[SENTRY_NAMESPACE]
    sentryQueue?.forEach((callback) => callback(Sentry))
  })
}
