import {ApiError} from '@rambler-id/errors'
import {getFirstPartyOrigin} from '@rambler-id/itp'
import {randomString} from '../utils/string'
import {ApiCallback} from '../types'

const API_ORIGIN = getFirstPartyOrigin()
const API_PREFIX = process.env.API_PREFIX

export const GET_PROFILE_INFO = '/profile/getInfo'
export const DESTROY_SESSION = '/legacy/Rambler::Id::destroy_session'
export const GET_RSIDX = '/session/getRsidx'
export const GET_RCCID = '/session/getRccid'
export const GET_CONFIGURATION = '/legacy/Rambler::Common::get_configuration'
export const SET_RSID_LOCAL = '/legacy/Rambler::Id::set_rsid_local'
export const REQUEST_QR_CODE =
  '/legacy/Rambler::Id::Session_copy::create_request'

export const request = (
  method: string,
  params: Record<string, any>,
  callback: ApiCallback,
  apiOrigin = API_ORIGIN
): void => {
  const request = new XMLHttpRequest()
  const url = `${apiOrigin}${API_PREFIX}${method}`
  const requestId = `rid${randomString()}`
  const body = {
    id: requestId,
    params
  }

  request.open('post', url, true)
  request.setRequestHeader('Content-Type', 'application/json')
  request.withCredentials = true

  request.onreadystatechange = () => {
    if (request.readyState !== 4) return
    if (request.status !== 200) return
    const {success, error, result} = JSON.parse(request.responseText)
    const legacyError = error?.extra?.legacy_error

    if (success && legacyError == null) {
      callback(null, result)

      return
    }

    if (legacyError == null) {
      callback(
        new ApiError({
          message: 'api failed',
          code: 0,
          details: {method, requestId}
        })
      )

      return
    }

    const code = legacyError.errno
    const message = legacyError.strerror?.toLowerCase()

    callback(
      new ApiError({
        message,
        code,
        details: {
          method,
          requestId
        }
      })
    )
  }

  request.onerror = () => {
    callback(
      new ApiError({
        message: 'api does not respond',
        code: 0,
        details: {
          method,
          requestId
        }
      })
    )
  }

  request.send(JSON.stringify(body))
}

export const getProfileInfo = request.bind(null, GET_PROFILE_INFO)
export const destroySession = request.bind(null, DESTROY_SESSION)
export const getRsidx = request.bind(null, GET_RSIDX)
export const getRccid = request.bind(null, GET_RCCID)
export const getConfiguration = request.bind(null, GET_CONFIGURATION)
export const setRsidLocal = request.bind(null, SET_RSID_LOCAL)
export const requestQRCode = request.bind(null, REQUEST_QR_CODE)
