const createRandomString = (length: number): string => {
  let result = ''

  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const randomArray = new Uint8Array(length)

  crypto.getRandomValues(randomArray)

  randomArray.forEach((number) => {
    result += chars[number % chars.length]
  })

  return result
}

export const randomString = (): string => createRandomString(17)
export const randomId = (): string => createRandomString(32)
