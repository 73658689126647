import {hasTrackingPrevention} from '@rambler-id/itp'
import {
  getProvidersOptions,
  providers,
  ProviderOptions
} from '@rambler-id/oauth'
import {webView} from '@rambler-id/browser'
import {addUrlParams, removeUrlHash} from '@rambler-id/url'
import {Events} from '../utils/events'
import {AuthWindowOptions, OAuthWindowOptions} from '../types'
import {BaseWidget} from './base-widget'
import {SharedEmitter} from './shared-emitter'

const CDN_ORIGIN = process.env.CDN_ORIGIN
const DATA_ID_FRAME = 'data-id-frame'

export class AuthWidget extends SharedEmitter implements BaseWidget {
  private static frame?: HTMLIFrameElement | Window

  private options!: AuthWindowOptions
  private container?: HTMLElement
  private closeInterval?: number

  private get type(): AuthWindowOptions['param'] {
    return this.options.container instanceof HTMLElement
      ? 'embed'
      : hasTrackingPrevention
        ? 'popup'
        : 'iframe'
  }

  private get frame(): HTMLIFrameElement | Window | undefined {
    return AuthWidget.frame
  }

  private set frame(frame: HTMLIFrameElement | Window | undefined) {
    AuthWidget.frame = frame
  }

  private get frameUrl(): string {
    const {
      path = '/login-20/login',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      container,
      startTime,
      ...options
    } = this.options
    const {href, protocol, host} = window.location

    options.back ??= href
    options.param = this.type
    options.iframeOrigin = `${protocol}//${host}`

    return addUrlParams(`${CDN_ORIGIN}${path}`, options, {startTime})
  }

  public constructor(options: AuthWindowOptions) {
    super({storage: false})
    this.configure(options)
  }

  private resize = ({height}: {height: number}): void => {
    if (this.container instanceof HTMLElement) {
      this.container.style.height = `${height}px`
    }
  }

  private redirect = (_: any, url: string): void => {
    window.location.href = url
  }

  private showOnLoaded(): void {
    const {frame, container} = this

    if (frame instanceof HTMLIFrameElement) {
      const show = (): void => {
        this.removeListener(Events.LOADED, show)
        frame.style.visibility = ''
        frame.style.height = '100%'

        if (container instanceof HTMLElement && this.type !== 'embed') {
          container.style.zIndex = '10000'
          container.style.transform = 'translateY(0)'
          container.style.opacity = '1'

          if (document.documentElement instanceof HTMLElement) {
            document.documentElement.style.overflow = 'hidden'
          }
        }
      }

      this.addListener(Events.LOADED, show)
    }
  }

  public configure({...options}: AuthWindowOptions): void {
    this.options = options
    this.container ??= options.container
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public attach(): void {
    let currentContainer = null

    try {
      currentContainer =
        this.frame instanceof HTMLIFrameElement
          ? this.frame.parentElement
          : null
    } catch {}

    if (
      this.type !== 'popup' &&
      this.frame instanceof HTMLIFrameElement &&
      (this.container == null ||
        (this.container instanceof HTMLElement &&
          this.container === currentContainer))
    ) {
      this.showOnLoaded()
      this.frame.src = this.frameUrl

      const currentUrl = removeUrlHash(this.frame.src)
      const newUrl = removeUrlHash(this.frameUrl)
      const isUrlChanged = currentUrl !== newUrl

      if (!isUrlChanged) {
        const handleFrameLoad = (): void => {
          this.frame?.removeEventListener('load', handleFrameLoad)

          // NOTE: if iframe route has not changed, we need to emit LOADED event to trigger showOnLoaded callback
          this.emit(Events.LOADED)
          this.frame?.focus()
        }

        this.frame.addEventListener('load', handleFrameLoad)
      }
    } else if (this.type === 'popup') {
      this.frame = window.open(
        this.frameUrl,
        'idFrame',
        `status=1,toolbar=0,menubar=0,resizable=1,scrollbars=1,width=800px,height=700px,top=100px,left=100px`
      ) as Window
    } else {
      this.frame = document.createElement('iframe')
      this.frame.src = this.frameUrl
      this.frame.frameBorder = '0'
      this.frame.style.visibility = 'hidden'
      this.frame.style.width = '100%'
      this.frame.style.display = 'block'
      this.frame.setAttribute('loading', 'eager')

      if (this.container instanceof HTMLElement) {
        this.frame.style.height = '0px'
        this.container.setAttribute(DATA_ID_FRAME, 'embed')
      } else {
        this.container = document.createElement('div')
        this.container.style.position = 'fixed'
        this.container.style.top = '0px'
        this.container.style.left = '0px'
        this.container.style.right = '0px'
        this.container.style.bottom = '0px'
        this.container.style.zIndex = '-1'
        this.container.style.transform = 'translateY(-100vh)'
        this.container.style.opacity = '0'
        this.container.style.transition = 'all 250ms'
        this.container.style.overflowY = 'auto'
        this.container.style.webkitOverflowScrolling = 'touch'
        this.container.setAttribute(DATA_ID_FRAME, 'own')
      }

      this.container.appendChild(this.frame)

      if (document.body && this.container.parentElement == null) {
        document.body.appendChild(this.container)
      }

      this.showOnLoaded()
    }

    if (this.type === 'embed') {
      this.addListener(Events.RESIZE, this.resize)

      if (this.frame instanceof HTMLIFrameElement) {
        this.frame.setAttribute('scrolling', 'no')
      }
    }

    this.addListener(Events.CLOSE, this.close)
    this.addListener(Events.REDIRECT, this.redirect)

    const {frame} = this

    if (!(frame instanceof HTMLIFrameElement)) {
      clearInterval(this.closeInterval)
      // NOTE unfortunately the popup window does not have any close event
      this.closeInterval = window.setInterval(() => {
        if (frame?.closed) {
          clearInterval(this.closeInterval)
          this.emit(Events.CLOSE)
        }
      }, 500)
    }
  }

  public close = (): void => {
    const {frame, container} = this

    if (!frame) {
      return
    }

    if (
      // NOTE: in safari IFrame content window is not an instance of Window
      !(frame instanceof HTMLIFrameElement) &&
      typeof frame.close === 'function'
    ) {
      clearInterval(this.closeInterval)
      frame.close()
      window.focus()

      return
    }

    if (container instanceof HTMLElement && this.type !== 'embed') {
      container.style.zIndex = '-1'
      container.style.transform = 'translateY(-100vh)'
      container.style.opacity = '0'

      if (document.documentElement instanceof HTMLElement) {
        document.documentElement.style.overflow = ''
      }
    }

    window.focus()
  }

  public destroy = (): void => {
    this.close()

    const {frame, container} = this

    if (
      frame instanceof HTMLIFrameElement &&
      container instanceof HTMLElement
    ) {
      if (this.type === 'embed') {
        // NOTE: timeout to emulate closing transition
        setTimeout(() => {
          container.removeChild(frame)
          container.removeAttribute(DATA_ID_FRAME)
        }, 500)
      } else if (document.body) {
        document.body.removeChild(container)
      }
    }

    delete AuthWidget.frame
    // NOTE: timeout to remove listeners after all current listeners fired
    setTimeout(() => {
      this.removeListener(Events.RESIZE, this.resize)
      this.removeListener(Events.CLOSE, this.close)
      this.removeListener(Events.REDIRECT, this.redirect)
    }, 0)
    this.emit(Events.DESTROY)
    super.destroy()
  }
}

export class OAuthWidget extends SharedEmitter implements BaseWidget {
  private options: OAuthWindowOptions
  private window?: Window | null
  private providersOptions: Record<string, ProviderOptions> =
    getProvidersOptions(providers)

  public constructor(options: OAuthWindowOptions) {
    super({storage: false})
    this.options = options
  }

  private get windowUrl(): string {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      targetWindow,
      startTime,
      ...options
    } = this.options

    options.popup ??= true
    options.on ??= 'login'
    options.back ??= window.location.href

    if (options.param === 'popup' && webView) {
      delete options.popup
    }

    return addUrlParams(`${CDN_ORIGIN}/oauth-20/`, options, {
      startTime
    })
  }

  public attach(): void {
    const {options, providersOptions, windowUrl} = this

    if (options.param === 'popup' && webView) {
      window.location.href = windowUrl
      options.targetWindow?.close()

      return
    }

    const height = providersOptions[options.provider]?.height ?? 750
    const width = providersOptions[options.provider]?.width ?? 450

    if (options.targetWindow) {
      options.targetWindow.location.href = windowUrl
      options.targetWindow.resizeTo(width, height)
      options.targetWindow.focus()

      return
    }

    this.window = window.open(
      windowUrl,
      'oauthWindow',
      `status=1,toolbar=0,menubar=0,resizable=1,scrollbars=1,width=${width}px,height=${height}px,top=100px,left=100px`
    )
  }

  public destroy(): void {
    this.window?.close()
  }
}
