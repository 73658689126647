import {getFirstPartyOrigin} from '@rambler-id/itp'
import type {HelperEventType} from 'types'

/** События ramblerIdHelper */
export enum Events {
  /** @internal */
  INSTALL = 'install',
  /** @internal */
  INSTALLED = 'installed',

  ERROR = 'error',
  REGISTER = 'register',
  LOGIN = 'login',
  OAUTHLOGIN = 'oauthlogin',
  OAUTHERROR = 'oautherror',
  LOGOUT = 'logout',
  CLOSE = 'close',
  REDIRECT = 'redirect',
  RESIZE = 'resize',
  LOADED = 'loaded',
  DESTROY = 'destroy',
  NAVIGATE = 'navigate',

  /** @internal */
  REQUEST_QR_CODE = 'request_qr_code',
  /** @internal */
  RESPONSE_QR_CODE = 'response_qr_code'
}

/** @internal events that expected only from id.rambler.ru origin */
export const ID_EVENTS = [
  Events.INSTALLED,
  Events.REGISTER,
  Events.CLOSE,
  Events.REDIRECT,
  Events.RESIZE,
  Events.LOADED,
  Events.NAVIGATE,
  Events.REQUEST_QR_CODE,
  Events.OAUTHERROR
]

const CDN_ORIGIN = process.env.CDN_ORIGIN
const FIRST_PARTY_CDN_ORIGIN = getFirstPartyOrigin()

export const isInsecureEvent = (
  eventType: HelperEventType,
  origin: string
): boolean => {
  const isInsecureOrigin =
    origin !== CDN_ORIGIN || origin !== FIRST_PARTY_CDN_ORIGIN

  return ID_EVENTS.includes(eventType) && isInsecureOrigin
}
