import {NAMESPACE} from './utils/constants'
import {HelperCallback} from './types'
import {RamblerIdHelper} from './components/rambler-id-helper'

export const registerHelper = (): void => {
  if (Array.isArray(window[NAMESPACE]) || !window[NAMESPACE]) {
    const queue: HelperCallback[] = window[NAMESPACE] || []

    window[NAMESPACE] = new RamblerIdHelper()
    queue.forEach((callback) => callback())
  }
}

if (document.body) {
  registerHelper()
} else {
  document.addEventListener('DOMContentLoaded', registerHelper, false)
}
